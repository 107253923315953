
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from "vue";
import { useRouter } from "vue-router"; //引入路由
import { caseList, newsList } from "./static/TestVariable";
export default defineComponent({
  name: "HomePage",
  components: {},
  setup() {
    const { proxy }: any = getCurrentInstance();
    const router = useRouter();
    let data = reactive({
      caseList: caseList,
      newsList: newsList,
      IsCollapse: true,
      Magnify: false,
      CurrentRouting: {
        nav: "",
        path: "",
      },
      title: "",
    });
    function FnAddMore() {
      const addMore = {
        title: "app设计案例",
        imgPath: "https://i.loli.net/2021/05/15/C4VyvfRS1idejMl.jpg",
      };
      data.caseList.push(addMore);
    }
    onMounted(() => {
      FnAddMore();
    });
    return {
      ...toRefs(data),
      proxy,
      router,
    };
  },
});
