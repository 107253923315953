export const caseList: Array<any> = [
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/36/78/45/62358PIC0Q6Z1FKBs7ee6_PIC2018.png!w1024_new_small",
  },
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/37/15/08/76M58PICiYzTPzign4CdF_PIC2018.png!w1024_new_small",
  },
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/40/10/65/02g58PICUWUEN658PICkvCmg4_PIC2018.png!w1024_new_small",
  },
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/36/81/93/99a58PICbMxhk3Pf4TEx7_PIC2018.jpg!w1024_new_small",
  },
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/36/78/45/66f58PICShpMTXidQeksy_PIC2018.png!w1024_new_small",
  },
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/36/81/67/58PIC2c58PICg59s77fcXbcRG_PIC2018.jpg!w1024_new_small",
  },
  {
    title: "app设计案例",
    imgPath:
      "https://preview.qiantucdn.com/58pic/36/21/02/47q58PICzw3AcZ8JK5eWf_PIC2018.jpg!w1024_new_small",
  },
];

export const newsList: Array<any> = [
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
  {
    day: "08",
    time: "2021-03",
    title: "醒了！继续开工！嫦娥四号进入第28月昼",
    sutitle:
      "来自国家航天局的消息，今天（3月8日），嫦娥四号着陆器和“玉兔二号”月球车完成唤醒设置，迎来了第28月昼。截至今天，嫦娥四号已在月球背面顺利工作795个地球日。",
  },
];
